<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav " fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <mat-toolbar>
      <span>Menu</span>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/']">Dashboard</a>
      <a mat-list-item [routerLink]="['/applyreport']">Apply Report</a>
      <a mat-list-item [routerLink]="['/table']">Table</a>
      <a mat-list-item [routerLink]="['/tree']">Tree</a>
      <a mat-list-item [routerLink]="['/drag']">Drag Drop</a>
      <!-- <a mat-list-item [routerLink]="['/survey']">Survey</a>
      <a mat-list-item [routerLink]="['/survey-view/1']">Survey View</a> -->
      <a mat-list-item [routerLink]="['/survey-list']">Survey List</a>
      <!-- <a mat-list-item [routerLink]="['/creator']">Creator</a> -->
      <a mat-list-item [routerLink]="['/file']">File</a>
      <a mat-list-item [routerLink]="['/chat']">Chat Bot</a>
      <a mat-list-item [routerLink]="['/h5p-player']">H5P Player</a>
      <a mat-list-item [routerLink]="['/h5p-list']">H5P Conent List</a>
      <a mat-list-item [routerLink]="['/calendar']">Calendar</a>
      <a mat-list-item [routerLink]="['/course-booking']">Course Booking</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
       >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span [routerLink]="''"><img  src="./assets/logo/logo-blue.png" class="app-logo"></span>
      <span class="spacer"></span>
      <span class="user-info">{{name}}</span>
      <button mat-icon-button [matMenuTriggerFor]="profile">
        <mat-icon class="posRel">portrait</mat-icon>
      </button>

      <mat-menu #profile="matMenu">
        <button mat-menu-item (click)="logout()" id="logout">
            <mat-icon color="accent">exit_to_app</mat-icon>
            <span>{{ 'Sign out' }}</span>
        </button>
      </mat-menu>

      <app-menu 
        [options]="options$ | async"
        (themeChange)="themeChangeHandler($event)">
      </app-menu>
    </mat-toolbar>
    <!-- Add Content Here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
