<div>
  <div style="margin: 20px;">
    <h2>Survey Creator - create a new survey</h2>
  </div>
  <div class="mar-20" style="margin: 20px;">
    <label>Survey Type: <select [(ngModel)]="type">
      <option value="Select">Select</option>
      <option value="UserProfile">UserProfile</option>
      <option value="Observation">Observation</option>
      </select></label>
  </div>
  <svc-creator [json]="json" (surveySaved)="onSurveySaved($event)"></svc-creator>
</div>