import { Component, OnInit, ViewChild } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import data from "../../../assets/surveys/responses/pre-admission.json";
import json from "../../../assets/surveys/template/pre-admission.json";
import { SurveyService } from "../creator/survey.service";
import { SurveyComponent } from "./survey.component";

@Component({
  selector: "survey-view",
  templateUrl: "./survey-view.page.html",
  styleUrls: ['./survey-view.page.css'],
  styles: ['--primary: #1ab7fa;  --background: #555555;  --background-dim: #4d4d4d; --background-dim-light: #4d4d4d; --primary-foreground: #ffffff; --foreground: #ededed; --base-unit: 8px;']
})
export class SurveyView implements OnInit {
  @ViewChild('survey') survey: SurveyComponent;
  title = "SurveyJS+Angular Demo Application";
  json;
  data;
  config: object;
  id: string
  constructor(private readonly route: ActivatedRoute, private readonly surveyService: SurveyService,private readonly router: Router) {
  }
  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.json= this.route.snapshot.data['data']['definition'];
    console.log(json);
    
    this.data= this.route.snapshot.data['responseData']["responseJSON"];
    console.log(this.route.snapshot.url["path"],"path");
     
    if(this.route.snapshot.url[0].path.indexOf("edit")==-1){

      this.config = {
        "mode": "display"
      }
    }
  }
 


  sendData(result) {
    //TODO update with your own behavior
    this.surveyService.saveResponse(result,this.route.snapshot.paramMap.get('responseId'),this.route.snapshot.data['responseData']).subscribe(e=>{
      this.router.navigate(['survey-reponses',this.id]);
      console.log("SAVED RESPONSE",e);
    })
  }
}

