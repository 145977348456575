import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  defineElements
} from '@lumieducation/h5p-webcomponents';
import { timeStamp } from 'console';
import { ContentService, IContentListEntry } from './services/ContentService';
import { LoginService } from './services/LoginService';


export class State {
  constructor(json) {
    Object.assign(this, json);
  }
  editing: boolean;
  loading: boolean;
  playing: boolean;
  saved: boolean;
  saving: boolean;
  saveError: boolean;
  saveErrorMessage: string;
  showingCustomCopyright: boolean;
  isLogged: boolean;
  loginData: Object;
  loginMessage: string;
}
@Component({
  selector: 'app-h5p-react-player',
  templateUrl: './h5p-react-player.component.html',
  styleUrls: ['./h5p-react-player.component.css']
})
export class H5pReactPlayerComponent implements OnInit {

  constructor(private readonly contentService: ContentService, private loginService: LoginService, private router: Router) { }
  contentId: number = undefined;
  h5pEditor: any;
  props: {
    contentService: ContentService;
    data: IContentListEntry;
    onDiscard: (content: IContentListEntry) => void;
    onDelete: (content: IContentListEntry) => void;
    onSaved: (data: IContentListEntry) => void;
    generateDownloadLink: (contentId: string) => string;
  }
  state: State;

  // private h5pEditor: H5PEditorWebComponent
  ngOnInit(): void {
    //initalize the editor
    defineElements('h5p-editor');
    //get previous state
    let stateObj: any = localStorage.getItem('h5p-editor-state');
    if (stateObj !== null) {
      this.state = new State(JSON.parse(stateObj));
    } else {
      this.state = new State(this.setDetfaultState());
      localStorage.setItem('h5p-editor-state', JSON.stringify(this.state));
    }

    this.create()
  }



  setDetfaultState(): any {
    return {
      editing: false,
      playing: false,
      saving: false,
      saved: false,
      loading: false,
      saveErrorMessage: '',
      saveError: false,
      showingCustomCopyright: false,
      isLogged: false,
      loginData: {},
      loginMessage: ''
    };
  }


  create() {
    if (!this.state.editing) {

      this.h5pEditor = document.createElement('h5p-editor');
      this.h5pEditor.setAttribute('content-id', 'new');
      this.h5pEditor.loadContentCallback = this.contentService.getEdit;
      this.h5pEditor.saveContentCallback = this.contentService.save;
      let someOtherElement = document.getElementById("h5p-editior-container-vg");
      someOtherElement.appendChild(this.h5pEditor);
      this.state.editing = true;
    }
  }

  async save() {
    try {
      const returnData = await this.h5pEditor?.save();
      if (returnData) {
        await this.props.onSaved({
          contentId: returnData.contentId,
          mainLibrary: returnData.metadata.mainLibrary,
          title: returnData.metadata.title,
          originalNewKey: this.props.data.originalNewKey
        });
        this.router.navigate(['/h5p-list'])
      }
    } catch (error) {
      // We ignore the error, as we subscribe to the 'save-error' and
      // 'validation-error' events.
    }
  }

  reset() {
    let someOtherElement: Element = document.getElementById("h5p-editior-container-vg");
    someOtherElement.innerHTML = "";
    this.router.navigate(['/h5p-list'])
  }
}

