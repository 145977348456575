import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';

@Component({
  selector: 'app-course-booking',
  templateUrl: './course-booking.component.html',
  styleUrls: ['./course-booking.component.css']
})
export class CourseBookingComponent implements OnInit {

  constructor(private http: HttpClient) { }

  baseUrl = 'https://reedctpdevservices.sequation.net/sequation-payment-api';
  course = {
    title: 'Accounting in a Small Business',
    location: 'Online',
    date: '23 Jul 2024',
    duration: '5 days',
    fullPrice: 133.50,
    depositAmount: 50.00,
    remainingAmount: 83.50
  };
  paymentOption: 'full' | 'installments' = 'full';

  ngOnInit(): void {
  }

  initiatePayment() {
    
    this.http.post(this.baseUrl + '/initiate', {}).subscribe((response: any) => {
      // Redirect to the Worldpay Hosted Payment Page
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = response.redirectUrl;;

      for (const key in response) {
        if (response.hasOwnProperty(key)) {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = response[key];
          form.appendChild(hiddenField);
        }
      }
      document.body.appendChild(form);
      form.submit();
    });
  }


}
