import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BaseUrl } from "src/app/framework/constants/url-constants";


@Injectable({
    providedIn: 'root'
})
export class LoginService {

    constructor(private readonly http: HttpClient) { }


    login  (username) {
        const href = `/login`;
    //return this.http.post<any>(href,data);
      return  fetch(href, {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: username
            })
        })
            
    };

    logout() {
        return fetch('/logout', {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-type': 'application/json',
                'CSRF-Token': localStorage.getItem("csrfToken")?? ''
            },
            body: JSON.stringify({})
        })
           
    };

}