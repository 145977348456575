import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Patient } from '../patient';
import { ReportService } from './apply-report.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as Circos from 'circos/dist/circos.es6.js';
import { ReportDataService } from './report-data.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-apply-report',
  templateUrl: './apply-report.component.html',
  styleUrls: ['./apply-report.component.css']
})
export class ApplyReportComponent implements OnInit {

  nextflowDockerList: Array<any> = [
    { key: "sequationdev.azurecr.io/nextflowf-azure-batch:latest", value: "Test Docker" },
    { key: "sequationdev.azurecr.io/snp-picker-pipeline:latest", value: "Report Trigger" },
    { key: "sequationdev.azurecr.io/nextflow-trigger-tools:test", value: "Test Report Trigger" },
    { key: "sequationdev.azurecr.io/nextflow-trigger-tools:latest", value: "Prod Report Trigger" }
  ];
  genomeDockerList: Array<any> = [
    { key: "vinishgeorge/genomics:latest", value: "Public Docker" },
    { key: "sequationdev.azurecr.io/genomics:latest", value: "Genomics" },
    { key: "sequationdev.azurecr.io/genome-tools:latest", value: "Genome-Tools" }
  ];

  fileNameList: Array<any> = [
    { key: "AZ_SNP_picker.nf", value: "Azure SNP Picker" },
    { key: "SNP_picker.nf", value: "SNP Picker" }
  ]

  configFileNameList: Array<any> = [
    { key: "nf-az.config", value: "Azure config" }
  ]
  gieStainColor = {
    gpos100: 'rgb(0,0,0)',
    gpos: 'rgb(0,0,0)',
    gpos75: 'rgb(130,130,130)',
    gpos66: 'rgb(160,160,160)',
    gpos50: 'rgb(200,200,200)',
    gpos33: 'rgb(210,210,210)',
    gpos25: 'rgb(200,200,200)',
    gvar: 'rgb(220,220,220)',
    gneg: 'rgb(255,255,255)',
    acen: 'rgb(217,47,39)',
    stalk: 'rgb(100,127,164)',
    select: 'rgb(135,177,255)'
  }
  patientForm: FormGroup;
  output: string = "Test Output";
  overlay: boolean = false;
  constructor(private readonly applyReportService: ReportService,
    private readonly reportDataService: ReportDataService,
    private readonly router: Router, private readonly fb: FormBuilder) {
    this.patientForm = this.fb.group({
      patientId: ['', Validators.required],
      patientName: ['', Validators.required],
      nextflowDocker: ['', Validators.required],
      genomeDocker: ['', Validators.required],
      fileName: ['', Validators.required],
      configFileName: ['', Validators.required]
    });
  }

  ngOnInit(): void {


    const req1 = this.reportDataService.getGenomeFile("assets/genome-data/cytobands.csv", "text")
    
    const req2 = this.reportDataService.getGenomeFile("assets/genome-data/fusion-genes.csv", "text")
    const req3 = this.reportDataService.getGenomeFile("assets/genome-data/GRCh37.json", null)
    const req4 = this.reportDataService.getGenomeFile("assets/genome-data/routinefamilialhypercholesterolemiaexample.csv", "text")
    const requestArray = [req1,req2,req3,req4];
    forkJoin(requestArray).subscribe(results => {
     

      let data = this.convertCSVToJSON(results[1], ',').map(d => {
        return {
          source: {
            id: d.source_id,
            start: parseInt(d.source_breakpoint),
            end: parseInt(d.source_breakpoint)
          },
          target: {
            id: d.target_id,
            start: parseInt(d.target_breakpoint),
            end: parseInt(d.target_breakpoint)
          }
        }
      })
    
      let cytobands = this.convertCSVToJSON(results[0], ',').map(d => {

        return {
          block_id: d.chrom,
          start: parseInt(d.chromStart),
          end: parseInt(d.chromEnd),
          gieStain: d.gieStain,
          name: d.name
        }
      });
      console.log(this.convertCSVToJSON(results[3], ','));
      let custom = this.convertCSVToJSON(results[3], ',').map(d => {

        return {
          block_id: d.CHROM,
          start: parseInt(d.POS),
          end: parseInt(d.POS),
          gieStain: 'select',
          name: "ALT: "+d.ALT+
          " BASE1: "+d.BASE1+
          " BASE2:"+d.BASE2+
          " CHROM: "+d.CHROM+
          " DP_BAM: "+d.DP_BAM+
          " GATK_INFO :" +d.GATK_INFO+
          " POS: "+d.POS+
          " POSTPROB1: "+d.POSTPROB1+
          " POSTPROB1: "+d.POSTPROB1+
          " QUAL1: "+d.QUAL1+
          " QUAL2: "+d.QUAL2+
          " REF_BASE: "+d.REF_BASE
        }
      });
      console.log(custom);
      this.drawCircos(results[2], cytobands, data,'chart');
      this.drawCircos(results[2],custom , data,'custom-chart');

    });



  }



  drawCircos(GRCh37, cytobands, data,div) {
    var gieStainColor = this.gieStainColor;
    var width = 500;
    var circos = new Circos({
      container: '#'+div,
      width: width,
      height: width
    })



    circos
      .layout(
        GRCh37,
        {
          innerRadius: width / 2 - 80,
          outerRadius: width / 2 - 40,
          labels: {
            radialOffset: 70
          },
          ticks: {
            display: true,
            labelDenominator: 1000000
          },
          events: {
            'click.demo': function (d, i, nodes, event) {
              console.log('clicked on layout block', d, event)
            }
          }
        }
      )
      .highlight('cytobands', cytobands, {
        innerRadius: width / 2 - 80,
        outerRadius: width / 2 - 40,
        opacity: 0.3,
        strokeColor:function (d) {
         
          return gieStainColor[d.gieStain]
        },
        strokeWidth:1,
        color: function (d) {
         
          return gieStainColor[d.gieStain]
        },
        tooltipContent: function (d) {
          return d.name
        }
      })
      // .chords(
      //   'l1',
      //   data,
      //   {
      //     radius: function (d) {
      //       if (d.source.id === 'chr1') {
      //         return 0.5
      //       } else {
      //         return null
      //       }
      //     },
      //     logScale: false,
      //     opacity: 0.7,
      //     color: '#ff5722',
      //     tooltipContent: function (d) {
      //       return '<h3>' + d.source.id + ' ➤ ' + d.target.id + ': ' + d.value + '</h3><i>(CTRL+C to copy to clipboard)</i>'
      //     },
      //     events: {
      //       'mouseover.demo': function (d, i, nodes, event) {
      //         console.log(d, i, nodes, event.pageX)
      //       }
      //     }
      //   }
      // )
      .render()

      


  }


  toggelOverlay(flag: boolean) {
    if (flag == null) {
      flag = true;
    }
    this.overlay = !this.overlay;
  }

  applyReport() {
    this.toggelOverlay(false)
    this.applyReportService.applyReport(this.patientForm.value as Patient).subscribe(
      response => {
        this.output = response.responseMessage;
        this.toggelOverlay(true);
      }, error => {
        alert("error");
        this.toggelOverlay(true);
      });
  }

  convertCSVToJSON(str, delimiter = ',') {
    const titles = str.slice(0, str.indexOf('\n')).split(delimiter);
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    return rows.map(row => {
      const values = row.split(delimiter);
      return titles.reduce((object, curr, i) => (object[curr] = values[i], object), {})
    });
  };

}
