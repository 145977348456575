import { Injectable } from '@angular/core';
import type {
    IContentMetadata, IEditorModel,
    IPlayerModel
} from '@lumieducation/h5p-server';
import { BaseUrl } from 'src/app/framework/constants/url-constants';
import { environment } from 'src/environments/environment';

export interface IContentListEntry {
    contentId: string;
    mainLibrary: string;
    title: string;
    originalNewKey?: string;
}
export interface IContentService {
    delete(contentId: string): Promise<void>;
    getEdit(contentId: string): Promise<IEditorModel>;
    getPlay(contentId: string): Promise<IPlayerModel>;
    list(): Promise<IContentListEntry[]>;
    save(
        contentId: string,
        requestBody: { library: string; params: any }
    ): Promise<{ contentId: string; metadata: IContentMetadata }>;
    generateDownloadLink(contentId: string): string;
}
@Injectable({
    providedIn: 'root'
})
export class ContentService implements IContentService {



    /**
     *
     */
    constructor() { }
    private baseUrl: string = 'h5p';

    delete = async (contentId: string): Promise<void> => {
        console.log(`ContentService: deleting ${contentId}...`);
        const result = await fetch(`${this.baseUrl}/${contentId}`, {
            method: 'delete',
            headers: {
                'CSRF-Token': localStorage.getItem("csrfToken") ?? ''
            }
        });
        if (!result.ok) {
            throw new Error(
                `Error while deleting content: ${result.status} ${result.statusText
                } ${await result.text()}`
            );
        }
    };

    getEdit = async (contentId: string): Promise<IEditorModel> => {
        console.log(
            `ContentService: Getting information to edit ${contentId}...`
        );
        const res = await fetch(`${this.baseUrl}/${contentId}/edit`);
        if (!res || !res.ok) {
            throw new Error(`${res.status} ${res.statusText}`);
        }
        return res.json();
    };

    getPlay = async (contentId: string): Promise<IPlayerModel> => {
        console.log(
            `ContentService: Getting information to play ${contentId}...`
        );
        const res = await fetch(`${this.baseUrl}/${contentId}/play`);
        if (!res || !res.ok) {
            throw new Error(`${res.status} ${res.statusText}`);
        }
        return res.json();
    };

    list = async (): Promise<IContentListEntry[]> => {
        console.log(`ContentService: Listing content objects`);
        const result = await fetch(this.baseUrl);
        if (result.ok) {
            return result.json();
        }
        throw new Error(
            `Request to REST endpoint returned ${result.status} ${result.statusText
            }: ${await result.text()}`
        );
    };

    save = async (
        contentId: string,
        requestBody: { library: string; params: any }
    ): Promise<{ contentId: string; metadata: IContentMetadata }> => {
        if (contentId) {
            console.log(`ContentService: Saving new content.`);
        } else {
            console.log(`ContentService: Saving content ${contentId}`);
        }

        const body = JSON.stringify(requestBody);

        const res = contentId
            ? await fetch(`${this.baseUrl}/${contentId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': localStorage.getItem("csrfToken") ?? ''
                },
                body
            })
            : await fetch(this.baseUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': localStorage.getItem("csrfToken") ?? ''
                },
                body
            });

        if (!res || !res.ok) {
            throw new Error(
                `${res.status} ${res.statusText} - ${await res.text()}`
            );
        }
        return res.json();
    };
    generateDownloadLink = (contentId: string): string =>
        `${this.baseUrl}/download/${contentId}`;

    setCsrfToken = (csrfToken): void => {
        localStorage.setItem("csrfToken", csrfToken);
    };
    getCsrfToken = (): string | undefined => {
        return localStorage.getItem("csrfToken");
    };

    sendXApi = (
        event,
        statement: any,
        context: any
    ) => {
        console.log(event,statement, context)

        const xAPI = event?.detail?.statement;
        if(xAPI){

            /*
             * The submit button og H5P.InteractiveVideo triggers an xAPI event with the
             * verb 'completed' for the context of IV, so we can check for xAPI+
             * statements for that.
             *
             * verb.id is a mandatory property of xAPI statements, context is optional,
             * hence the checks.
             */
            console.log("verb==>"+xAPI.verb.id);
            console.log("result==>"+xAPI?.result?.score?.raw+"/"+xAPI?.result?.score?.max)
            const verbIsAnswered = xAPI.verb.id === 'http://adlnet.gov/expapi/verbs/completed';
            const contextIsIV = xAPI.context && xAPI.context.contextActivities && xAPI.context.contextActivities.category &&
                xAPI.context.contextActivities.category.length > 0 &&
                xAPI.context.contextActivities.category[0].id &&
                xAPI.context.contextActivities.category[0].id.indexOf('H5P.InteractiveVideo') !== -1;
            console.log(verbIsAnswered,contextIsIV);
            if (!verbIsAnswered || !contextIsIV) {
                return
            }
        }
    };

}
