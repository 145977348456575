import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { Option } from '../theme/options.model';
import { ThemeService } from '../theme/theme.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-az-nav',
  templateUrl: './az-nav.component.html',
  styleUrls: ['./az-nav.component.css']
})
export class AzNavComponent implements OnInit {
  name
  options$: Observable<Array<Option>> = this.themeService.getThemeOptions();

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private readonly router: Router,
    private readonly themeService: ThemeService,
    private readonly authService: MsalService,
    private readonly  auth: AuthService
  ) { }

  ngOnInit() {
    if (localStorage.getItem("sso-ip") === "msal") {
    console.log(this.authService.instance.getAccountByUsername(localStorage.getItem('username')))
    this.name = this.authService.instance.getAccountByUsername(localStorage.getItem('username')).name;
    }else{
      this.auth.user$.subscribe(res => {
        console.log(res);
        this.name = res.name
      });
    }
    this.themeService.setTheme("purple-green");
  }

  themeChangeHandler(themeToSet) {
    this.themeService.setTheme(themeToSet);
  }
  logout() {
    if (localStorage.getItem("sso-ip") === "msal") {
      this.authService.logoutPopup().subscribe(rsp => {
        localStorage.clear();
        this.router.navigate(['/sessions/sign-in']);
      });
    }else{
      this.auth.logout({ logoutParams: { returnTo: document.location.origin+'/sessions/sign-in' } }).subscribe(() => {
        localStorage.clear();
        this.router.navigate(['/sessions/sign-in']);
      });
    }
  }
}
