import { Component, OnInit } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploadService } from './file-upload.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {

  fileUploadForm: FormGroup;
  overlay: boolean = false;
  selectedFile: any = null;
  constructor(private readonly fb: FormBuilder,private readonly uploadService:FileUploadService) {
    this.fileUploadForm = this.fb.group({
      fileName: ['', Validators.required],
      fileSource: []
    });
  }

  ngOnInit(): void {
  }
  toggelOverlay(flag: boolean) {
    if (flag == null) {
      flag = true;
    }
    this.overlay = !this.overlay;
  }
  uploadFile(): boolean {
    this.toggelOverlay(false);
    let formData:FormData = new FormData();
    formData.set("fileName",this.fileUploadForm.get("fileName").value);
    formData.set("fileSource",this.selectedFile );
    this.uploadService.uploadFile(formData).subscribe(
      response => {
       console.log(response.responseMessage);
        this.toggelOverlay(true);
      }, error => {
        alert("error");
        this.toggelOverlay(true);
      });;
    return false;
  }
  
  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0] ?? null;
  }
}
