<mat-card class="booking-card">
    <mat-card-header>
      <mat-card-title>Book this course</mat-card-title>
    </mat-card-header>
    <button mat-icon-button routerLink="/courses">
      <mat-icon>arrow_back</mat-icon>
    </button>
    
    <mat-card-content>
      <h2>Your course</h2>
      <mat-list>
        <mat-list-item>
          <span matListItemTitle>Title:&nbsp;</span>
          <span matListItemLine>{{course.title}}</span>
        </mat-list-item>
        <mat-list-item>
          <span matListItemTitle>Location:&nbsp;</span>
          <span matListItemLine>{{course.location}}</span>
        </mat-list-item>
        <mat-list-item>
          <span matListItemTitle>Date:&nbsp;</span>
          <span matListItemLine>{{course.date}}</span>
        </mat-list-item>
        <mat-list-item>
          <span matListItemTitle>Duration:&nbsp;</span>
          <span matListItemLine>{{course.duration}}</span>
        </mat-list-item>
      </mat-list>
  
      <h3>Choose how to pay</h3>
      <mat-radio-group [(ngModel)]="paymentOption">
        <mat-radio-button value="full">
          Pay £{{course.fullPrice}} now (Pay now, pay less)
        </mat-radio-button>
        <mat-radio-button value="installments">
          Pay £{{course.depositAmount}} now today, 1 payment of £{{course.remainingAmount}}
        </mat-radio-button>
      </mat-radio-group>
  
      <h3>Pay your deposit</h3>
      <mat-form-field appearance="fill">
        <mat-label>Card number</mat-label>
        <input matInput placeholder="Enter card number">
      </mat-form-field>
      <p class="awaiting-confirmation">Awaiting confirmation on World Pay name</p>
  
      <h4>Cancellation policy</h4>
      <p>Free cancellation before 5 Sept. Cancel before yr on 14 Sept for a partial refund. <a href="#">Learn more</a></p>
  
      <mat-checkbox>I agree to the booking terms</mat-checkbox>
    </mat-card-content>
  
    <mat-card-actions>
      <button mat-raised-button color="primary" (click)="initiatePayment()">Pay & Reserve Your Space</button>
    </mat-card-actions>
  </mat-card>