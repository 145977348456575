<div class="mat-elevation-z8">
  <button mat-raised-button aria-label="Add" (click)="answer()"
  style="float: right;">Add</button>
    <div>
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedColumns">
          <ng-container *ngIf="column !== 'actions' && column.indexOf('.')==-1;else elseif">
            <mat-header-cell *matHeaderCellDef class="capitalize"> {{column}} </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row[column]}} </mat-cell>
          </ng-container>
          <ng-template #elseif>
            <ng-container *ngIf="column !== 'actions' &&  column.indexOf('.')>0;else action">{{column.indexOf('.')>0}}
              <mat-header-cell *matHeaderCellDef class="capitalize">{{column | splitPipe:'.':1}} </mat-header-cell>
              <mat-cell *matCellDef="let row">{{row| getNestedData:column }} </mat-cell>
            </ng-container>
          </ng-template>
          <ng-template #action>
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let row" class="blue-icon">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="view(row)">
                  <mat-icon class="margin-right-5"  >remove_red_eye</mat-icon>
                  <span>View</span>
                </button>
                <button mat-menu-item (click)="edit(row)">
                  <mat-icon class="margin-right-5" >edit</mat-icon>
                  <span>Edit</span>
                </button>
                <button mat-menu-item (click)="delete(row)">
                  <mat-icon class="margin-right-5" >delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
             
              
              
            </mat-cell>
          </ng-template>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="50"
      [pageSizeOptions]="[25, 50, 100, 250]">
    </mat-paginator>
  </div>