import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitPipe'
})
export class SplitPipePipe implements PipeTransform {

  transform(value: string, char:string,position:number): string {
    return value.split(char)[position];
  }

}
