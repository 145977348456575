import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseUrl } from '../framework/constants/url-constants';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private readonly http: HttpClient) { }

  
  uploadFile(data:FormData) {
    const href = `${BaseUrl.FILE_API}/upload`;
    return this.http.post<any>(href,data);
  }
  

}
