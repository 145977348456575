import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseUrl } from 'src/app/framework/constants/url-constants';
import { SchemaDefinition } from './schemaDefinition.model';
import { SchemaResponse } from './schemaResponse.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  
  constructor(private readonly http: HttpClient) { }
  
  getReponseListView(id:String) {
    const href = `${BaseUrl.SURVEY}/schema-response/listView/${id}`;
    return this.http.get<any>(href);
  }
  getSchemaResponse(id: string): Observable<SchemaResponse> {
    const href = `${BaseUrl.SURVEY}/schema-response/get/${id}`;
    return this.http.get<any>(href);
  }
  deleteResponse(id: string) {
    const href = `${BaseUrl.SURVEY}/schema-response/delete/${id}`;
    return this.http.delete<any>(href);
  }
  deleteSchemaDefinition(id: string) {
    const href = `${BaseUrl.SURVEY}/schema-definition/delete/${id}`;
    return this.http.delete<any>(href);
  }
  getSchemaResponses(id:String): Observable<SchemaResponse[]> {
    const href = `${BaseUrl.SURVEY}/schema-response/list/${id}`;
    return this.http.get<any>(href);
  }
  getSchemaDefinitions(): Observable<SchemaDefinition[]> {
    const href = `${BaseUrl.SURVEY}/schema-definition/list`;
    return this.http.get<any>(href);
  }
  getSchemaDefinition(id: string): Observable<SchemaDefinition> {
    const href = `${BaseUrl.SURVEY}/schema-definition/get/${id}`;
    return this.http.get<any>(href);
  }
  saveResponse(data, id,schemaResponse?: SchemaResponse) {
    if(!schemaResponse){
      schemaResponse = { "clientId": 1, "userId": 1, "schemaId": id, "responseJSON": data };
      const href = `${BaseUrl.SURVEY}/schema-response/create`;
      return this.http.post<any>(href, schemaResponse);
    }else{
      schemaResponse.responseJSON=data;
      const href = `${BaseUrl.SURVEY}/schema-response/update`;
      return this.http.put<any>(href, schemaResponse);
    }
  }
  setUpData(data: any, type, schemaDefinition: SchemaDefinition) {
    if (!schemaDefinition) {
      const create = `${BaseUrl.SURVEY}/schema-definition/create`;
      schemaDefinition = { "clientId": 1, "name": data["title"], "type": type, "definition": data };
      return this.http.post<any>(create, schemaDefinition);
    } else {
      schemaDefinition.name = data["title"];
      schemaDefinition.definition = data;
      const update = `${BaseUrl.SURVEY}/schema-definition/update`;
      return this.http.put<any>(update, schemaDefinition);
    }
  }
}
