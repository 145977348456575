<section>
    <div class="example-button-row">
        <button mat-raised-button color="accent" (click)="save()" [disabled]="!state?.isLogged ">Save</button>
        <button mat-raised-button color="warn" (click)="reset()" [disabled]="!state?.isLogged">Close</button>
    </div>
</section>
<!-- <div style="color: white;">
    <pre> 
        <code>
           {{ state | json }}

           {{!state?.isLogged || !state?.editing}}
        </code>
      </pre>

</div> -->
<div id="h5p-editior-container-vg"></div>