// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
  apiURL: 'http://localhost',
  logo: `./assets/logo/New Logo.png`,
  appTitle: 'MNADMIN',
  appInsights: {
    instrumentationKey: '451221f0-7c79-4813-9ea3-a8b385b2fd5b',
    disableExceptionTracking: true
  },
  azureBlobStorage: 'https://sequationdevsav2.blob.core.windows.net',
  //clientId: '21ed313a-c892-48e6-a328-fcc7dc7e5d3b',
  //issuerURL:'https://login.microsoftonline.com/f7efb976-68c3-4785-8860-a1ceaf80984a'
  clientId: '647e0c82-05f7-4a33-93f5-430cec0d93f2',
  issuerURL:'https://login.microsoftonline.com/5778ddf3-0ec2-4912-9f7c-6eb3674b2682'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
