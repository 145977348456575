import { environment } from "src/environments/environment";


export const BaseUrl = {
    REPORT: environment.apiURL,
    SURVEY:environment.apiURL+":9090/sequation-assessment-api",
    FILE_API:environment.apiURL+':8080/file-upload-api',
    H5P_API:environment.apiURL+":8080/h5p",
    LOGIN_API:environment.apiURL+":8080",
};
