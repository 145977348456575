import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { PaymentPopupComponent } from '../payment-popup/payment-popup.component';

@Component({
  selector: 'app-az-dashboard',
  templateUrl: './az-dashboard.component.html',
  styleUrls: ['./az-dashboard.component.css']
})
export class AzDashboardComponent {
  /** Based on the screen size, switch from standard to one column per row */
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Card 2', cols: 1, rows: 1 },
          { title: 'Card 3', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { title: 'Card 1', cols: 2, rows: 1 },
        { title: 'Card 2', cols: 1, rows: 1 },
        { title: 'Card 3', cols: 1, rows: 2 },
        { title: 'Card 4', cols: 1, rows: 1 }
      ];
    })
  );
  formData: any={
    instId: 'CF25492B4405CF5CA1438E84073',
    cartId: 'BK00001',
    amount: '1.00',
    currency: 'GBP',
    MC_userID: '1234'
  };

  constructor(private breakpointObserver: BreakpointObserver,public dialog: MatDialog) {}

  openPaymentPopup(): void {
    this.generateAuthValidityTimestamps();
    console.log(this.formData.instId+":"+this.formData.amount+":"+this.formData.currency+":"+this.formData.cartId);
    const dialogRef = this.dialog.open(PaymentPopupComponent, {
      width: '600px',
      data: this.formData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'paymentCompleted') {
        // Handle the completion of the payment here
        console.log('Payment completed successfully.');
      }
    });
  }

  generateAuthValidityTimestamps() {
    console.log("Generating auth validity timestamps...");
    
    const currentTime = Date.now();
    const twoMinutesInMillis = 2 * 60 * 1000; // 2 minutes in milliseconds

    this.formData.authValidFrom = currentTime;
    this.formData.authValidTo = currentTime + twoMinutesInMillis;
  }

}
