<div class="grid-container">
    <h1 class="mat-h1"> Apply Report</h1>
    <mat-grid-list cols="2" rowHeight="700px">
        <mat-grid-tile>
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        Apply Report
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <form [formGroup]="patientForm">
                        <div>
                            <p class="15space">
                                <mat-form-field appearance="standard">
                                    <mat-label>Patient ID</mat-label>
                                    <input matInput placeholder="Patient ID" formControlName="patientId">
                                    <mat-icon matSuffix>person</mat-icon>
                                    <mat-hint>Enter patient ID</mat-hint>
                                </mat-form-field>
                                <mat-form-field appearance="standard">
                                    <mat-label>Patient Name</mat-label>
                                    <input matInput placeholder="Patient Name" formControlName="patientName">
                                    <mat-icon matSuffix>person_outline</mat-icon>
                                    <mat-hint>Enter patient name</mat-hint>
                                </mat-form-field>
                            </p>
                            <p>
                                <mat-form-field appearance="fill">
                                    <mat-label>Nextflow Docker</mat-label>
                                    <mat-select placeholder="Nextflow Docker" formControlName="nextflowDocker">
                                        <mat-option *ngFor="let nextflow of nextflowDockerList" [value]="nextflow.key">
                                            {{nextflow.value}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Genome Docker</mat-label>
                                    <mat-select placeholder="Genome Docker" formControlName="genomeDocker">
                                        <mat-option *ngFor="let genome of genomeDockerList" [value]="genome.key">
                                            {{genome.value}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </p>
                            <p>
                                <mat-form-field appearance="fill">
                                    <mat-label>Nextflow Filename</mat-label>
                                    <mat-select placeholder="Nextflow Filename" formControlName="fileName">
                                        <mat-option *ngFor="let file of fileNameList" [value]="file.key">
                                            {{file.value}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Nextflow Config Filename</mat-label>
                                    <mat-select placeholder="Nextflow Docker" formControlName="configFileName">
                                        <mat-option *ngFor="let file of configFileNameList" [value]="file.key">
                                            {{file.value}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </p>

                            <button mat-raised-button aria-label="Apply Report" (click)="applyReport()"
                                [disabled]='!patientForm.valid'>Apply Report</button>
                            <!-- <button mat-raised-button aria-label="Show overlay" (click)="isOverlay()"
                                >Show/Hide Overlay</button> -->
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile>
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        Output
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <div style="max-height: 300px ; overflow: auto;">
                        {{output}}
                    </div>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="2" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        Visualize
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <div id="chart">

                    </div>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="2" [rowspan]="1">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        Visualize
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <div id="custom-chart">

                    </div>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<app-mat-spinner-overlay [overlay]="this.overlay" [hidden]="!this.overlay"></app-mat-spinner-overlay>