import { Component, OnInit } from '@angular/core';
import { ContentService, IContentListEntry } from '../h5p-react-player/services/ContentService';
import { LoginService } from '../h5p-react-player/services/LoginService';

export class State {
  constructor(json) {
    Object.assign(this, json);
  }
  editing: boolean;
  loading: boolean;
  playing: boolean;
  saved: boolean;
  saving: boolean;
  saveError: boolean;
  saveErrorMessage: string;
  showingCustomCopyright: boolean;
  isLogged: boolean;
  loginData: Object;
  loginMessage: string;
}

@Component({
  selector: 'app-h5p-content-list',
  templateUrl: './h5p-content-list.component.html',
  styleUrls: ['./h5p-content-list.component.css']
})
export class H5pContentListComponent implements OnInit {

  constructor(private readonly contentService:ContentService,private loginService: LoginService) { }
 
  contentList:any;
  displayedColumns: string[];
  panelOpenState = false;
  props: {
    contentService: ContentService;
    data: IContentListEntry;
    onDiscard: (content: IContentListEntry) => void;
    onDelete: (content: IContentListEntry) => void;
    onSaved: (data: IContentListEntry) => void;
    generateDownloadLink: (contentId: string) => string;
  }
  state: State;

  ngOnInit(): void {
    let stateObj: any = localStorage.getItem('h5p-editor-state');
    if (stateObj!==null) {
      this.state = new State(JSON.parse(stateObj));
    } else {
      this.state = new State(this.setDetfaultState());
      localStorage.setItem('h5p-editor-state', JSON.stringify(this.state));
    }
    
    this.updateList();
  }

  setDetfaultState(): any {
    return {
      editing: false,
      playing: false,
      saving: false,
      saved: false,
      loading: false,
      saveErrorMessage: '',
      saveError: false,
      showingCustomCopyright: false,
      isLogged: false,
      loginData: {},
      loginMessage: ''
    };
  }
  login(userName) {
    this.loginService.login(userName).then(async (res) => {
      if (res.status === 200) {
        const loginData = await res.json();
        console.log(loginData);
        this.state.loginData = loginData;
        this.state.isLogged = true;
        this.state.loginMessage = '';
        localStorage.setItem("loginData", JSON.stringify(loginData));
        localStorage.setItem("csrfToken", loginData.csrfToken);

      } else {
        localStorage.setItem("csrfToken", undefined);
      }
      localStorage.setItem('h5p-editor-state', JSON.stringify(this.state));
    })
      .catch((reason) => {
        console.log(reason);
        this.state.loginMessage = reason;
        this.state.isLogged = false;
        localStorage.setItem("loginMessage", reason);
        localStorage.setItem("csrfToken", undefined);
        localStorage.setItem('h5p-editor-state', JSON.stringify(this.state));
      });
  }

  logout() {
    this.loginService.logout().then(() => {
      localStorage.setItem("csrfToken", undefined);
      localStorage.setItem("loginMessage", undefined);
      localStorage.setItem("loginData", undefined);
      this.state= new State(this.setDetfaultState());
      localStorage.setItem('h5p-editor-state', JSON.stringify(this.state));
    })
      .catch((reason) => {
        localStorage.setItem("csrfToken", undefined);
        localStorage.setItem("loginMessage", `Error logging out: ${reason}`);
        localStorage.setItem("loginData", undefined);
        this.state= new State(this.setDetfaultState());
        localStorage.setItem('h5p-editor-state', JSON.stringify(this.state));
      });
  }

  reset(){
    let someOtherElement:Element = document.getElementById("h5p-editior-container-vg");
    someOtherElement.innerHTML="";
  }

  async updateList(): Promise<void> {
    this.displayedColumns= ['contentId', 'title','action'];
    this.contentList = await this.contentService.list();
  }

  
}
