<!-- <p>h5p-content-list works!</p>
<div style="color: white;">
    <pre>
        <code>
           {{ contentList | json }}

        </code>
      </pre>

</div> -->
<section>
    <div class="example-button-row">
        <button mat-raised-button color="accent" (click)="login('teacher1')" [disabled]="state?.isLogged">Login</button>
        <a mat-raised-button color="accent" [routerLink]="['/h5p-editor']" [disabled]="!state?.isLogged">Create</a>
        <button mat-raised-button color="warn" (click)="logout()" [disabled]="!state?.isLogged">Logout</button>
    </div>
</section>
<mat-divider></mat-divider>
<div>
    <table mat-table [dataSource]="contentList" class="mat-elevation-z8">
        <ng-container matColumnDef="contentId">
            <th mat-header-cell *matHeaderCellDef> Content Id </th>
            <td mat-cell *matCellDef="let element"> {{element.contentId}} </td>
        </ng-container>
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let element">
                <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{element.title}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-divider></mat-divider>
                        <mat-list role="list">
                            <mat-list-item role="listitem">Created By : Vinish George</mat-list-item>
                            <mat-list-item role="listitem">Created on : 2nd June 1985</mat-list-item>
                            <mat-list-item role="listitem">Modified By: Vinish George</mat-list-item>
                            <mat-list-item role="listitem">Modified on: 22nd July 2019</mat-list-item>
                          </mat-list>
                    </mat-expansion-panel>
                </mat-accordion>
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
                <a mat-mini-fab disabled aria-label="view details" [routerLink]="['/h5p-player',element.contentId]">
                    <mat-icon>visibility</mat-icon>
                </a>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>