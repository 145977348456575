import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'console';
import { forkJoin } from 'rxjs';
import { SchemaDefinition } from '../creator/schemaDefinition.model';
import { SurveyService } from '../creator/survey.service';
import { ListDefination } from './ListDefination';

@Component({
  selector: 'app-schema-responses',
  templateUrl: './schema-responses.component.html',
  styleUrls: ['./schema-responses.component.css']
})
export class SchemaResponsesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  dataSource = new MatTableDataSource<any>();
  constructor(private readonly surveyService: SurveyService, private readonly router: Router, private readonly route: ActivatedRoute) {

  }
  schemaId;
  displayedColumns = [];
  def: ListDefination;
  ngOnInit(): void {
    //TODO: psuedo code
    /**
     *  get question with column name , column width etc
     * get responses with  
     *  */
    console.log(this.route.snapshot.params);
     this.schemaId=this.route.snapshot.params.id;

    this.getData();

  }

  getData() {

    
    this.surveyService.getReponseListView(this.schemaId).subscribe(data => {
      console.log("Data received",data);
      
     this.def= data;

     this.getColumnDefinition(this.def);

     this.getTableData(this.def);
    });

    // this.def = {
    //   "schemaId": "63f38d6b37a6143db6a474ce",
    //   "columnDef": [{
    //     "columnId": "questionid_1",
    //     "columnName": "first name",
    //     "columnWidth": 10,
    //     "sequence": 1

    //   }, {
    //     "columnId": "questionid_2",
    //     "columnName": "last name",
    //     "columnWidth": 10,
    //     "sequence": 2
    //   }],
    //   "responses": [{
    //     "questionid_1": "vinish",
    //     "questionid_2": "george",
    //     "id": "63f3930537a6143db6a474d8"
    //   }, {
    //     "questionid_1": "rosh",
    //     "questionid_2": "george",
    //     "id": "63f3931037a6143db6a474d9"
    //   }, {
    //     "questionid_1": "scarlett",
    //     "questionid_2": "george",
    //     "id": "63f392bf37a6143db6a474d7"
    //   }]
    // }

   
  }

  getTableData(def: ListDefination) {
    let data = [];
    for (let i = 0; i < def.responses.length; i++) {
      let responseDef = def.responses[i];
      let responseObj = this.getQuestionId(def, responseDef);

      if (responseObj)
        responseDef = responseObj;
      console.log("data Def", responseDef);
      data.push(responseDef);
    }
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getQuestionId(def: ListDefination, responseDef: any) {
    let questionIds = Object.keys(responseDef);
    let responseObj = {};
    
    for (let i = 0; i < questionIds.length; i++) {
      let found = false;
      
      for (let j = 0; j < def.columnDef.length; j++) {
        if (questionIds[i] == def.columnDef[j].name) {
          responseObj[def.columnDef[j].columnName] = responseDef[def.columnDef[j].name]
          found = true;
          break;
        }
      }
      if (!found)
        responseObj[questionIds[i]] = responseDef[questionIds[i]];
    }


    return responseObj;
  }
  getColumnDefinition(def: ListDefination) {
    for (let i = 0; i < def.columnDef.length; i++) {
      let columnDef = def.columnDef[i];
      console.log("Column Def", columnDef);
      this.displayedColumns.push(columnDef.columnName);

    }
    this.displayedColumns.push('actions');
  }
  view(data) {
    console.log("row data", data);
    this.router.navigate(['survey-view', this.def.schemaId, data["id"]]);
  }
  edit(data) {
    this.router.navigate(['survey-reponses-edit',this.def.schemaId, data["id"]]);
  }
  delete(data) {
    let confirmed = confirm("Are you sure?");
    if (confirmed) {
      this.surveyService.deleteResponse(data["id"]).subscribe(data => {
        console.log("Deleted .. ", data);
        this.getData()
      });
    }
  }
  answer() {
    this.router.navigate(['survey',  this.schemaId]);
  }
  create() {
    this.router.navigate(['creator']);
  }
  responses(data) {
    this.router.navigate(['survey-reponses', data["id"]]);
  }

}
