import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { jquerybarrating, icheck, select2, inputmask, jqueryuidatepicker, nouislider, select2tagbox, sortablejs, ckeditor, autocomplete, bootstrapslider } from "surveyjs-widgets";
import { init as initCustomWidget } from "../customwidget";
import { Serializer, StylesManager } from "survey-core";
import * as SurveyCore from "survey-core";
import { SurveyCreatorModel } from "survey-creator-core";

icheck(SurveyCore);
select2(SurveyCore);
inputmask(SurveyCore);
jquerybarrating(SurveyCore);
jqueryuidatepicker(SurveyCore);
nouislider(SurveyCore);
select2tagbox(SurveyCore);
//signaturepad(SurveyCore);
sortablejs(SurveyCore);
ckeditor(SurveyCore);
autocomplete(SurveyCore);
bootstrapslider(SurveyCore);
//emotionsratings(SurveyCore);
initCustomWidget(SurveyCore);

//StylesManager.applyTheme("modern");
StylesManager.applyTheme("defaultV2");
// Add a property to the Survey class

// var CkEditor_ModalEditor = {
//   afterRender: function(modalEditor, htmlElement) {
//     var editor = window["CKEDITOR"].replace(htmlElement);
//     editor.on("change", function() {
//       modalEditor.editingValue = editor.getData();
//     });
//     editor.setData(modalEditor.editingValue);
//   },
//   destroy: function(modalEditor, htmlElement) {
//     var instance = window["CKEDITOR"].instances[htmlElement.id];
//     if (instance) {
//       instance.removeAllListeners();
//       window["CKEDITOR"].remove(instance);
//     }
//   }
// };
// SurveyCreator.SurveyPropertyModalEditor.registerCustomWidget(
//   "html",
//   CkEditor_ModalEditor
// );
// CKEditor is the third party library
/* Steps to use CKEditor in the project:
    1. run 'npm i ckeditor'
    2. update the "build" section of the angular.json: add ckeditor.js script in the scripts section.
*/

@Component({
  selector: 'svc-creator',
  templateUrl: './survey-creator.component.html',
  styleUrls: ['./survey.creator.component.css']
})
export class SurveyCreatorComponent implements OnInit, AfterViewInit, OnDestroy {
  surveyCreatorModel: SurveyCreatorModel;
  @Input() json: any;
  @Output() surveySaved: EventEmitter<Object> = new EventEmitter();
  ngOnInit() {
    this.addFeatures();
    Serializer.addProperty(
      "questionbase",
      "popupdescription:text"
    );
    Serializer.addProperty("page", "popupdescription:text");

    let options = { showEmbededSurveyTab: true, generateValidJSON: true, haveCommercialLicense: true };
    this.surveyCreatorModel = new SurveyCreatorModel(
      options
    );
    this.surveyCreatorModel.text = JSON.stringify(this.json);
    this.surveyCreatorModel.saveSurveyFunc = this.saveMySurvey;
  }

  saveMySurvey = () => {

    console.log("schema json ->", JSON.stringify(this.surveyCreatorModel.text));
    console.log("JSON SCHEMA-> ", JSON.stringify(Serializer.generateSchema()));
    this.surveySaved.emit(JSON.parse(this.surveyCreatorModel.text));
  };

  ngOnDestroy(): void {
    console.log("in destroy")
  }
  ngAfterViewInit(): void {
    console.log("in after view init", this.json);

  }

  addFeatures() {
    console.log("Added List view features");

    Serializer.addProperty("question",
      {
        name: "isListViewCol",
        displayName: "Add to list view",
        category: "List View Options",
        type: "boolean"
      });
    Serializer.addProperty("question",
      {
        name: "columnName",
        displayName: "Name",
        category: "List View Options",
        dependsOn: ["isListViewCol"],
      });

    Serializer.addProperty("question",
      {
        name: "columnWidth",
        displayName: "Width",
        type: "number",
        category: "List View Options",
        dependsOn: ["isListViewCol"],
      });

    Serializer.addProperty("question",
      {
        name: "isSearchable",
        displayName: "Can be searched? ",
        type: "boolean",
        category: "List View Options",
        dependsOn: ["isListViewCol"],
      });

    Serializer.addProperty("question",
      {
        name: "isSortable",
        displayName: "Can be sorted?",
        type: "boolean",
        category: "List View Options",
        dependsOn: ["isListViewCol"],
      });

    Serializer.addProperty("question",
      {
        name: "sequence",
        displayName: "Sequence",
        type: "number",
        category: "List View Options",
        dependsOn: ["isListViewCol"],
      });
    Serializer.addProperty("question",
      {
        name: "isVisualized",
        displayName: "Add to dashboard",
        type: "boolean",
        category: "Dashboard View Options"
      });
    Serializer.addProperty("survey", {
      name: "Pre Population API URL",
      category: "general",
      visibleIndex: 2
    });

  }
}
