<div class="grid-container">
    <h1 class="mat-h1">Booking confirmed</h1>
    <mat-card>
        <mat-card-header>
            <mat-card-title>Payment Response</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <ul>
                <li *ngFor="let param of getQueryParamsKeys()">
                    {{ param }}: {{ queryParams[param] }}
                </li>
            </ul>
        </mat-card-content>
    </mat-card>
</div>