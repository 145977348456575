import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseUrl } from '../framework/constants/url-constants';
import { Patient } from '../patient';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private readonly http: HttpClient) { }

  
  applyReport(data:Patient) {
    const href = `${BaseUrl.REPORT}/batch`;
    return this.http.post<any>(href,data);
  }
  

}
