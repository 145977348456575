import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-popup',
  templateUrl: './payment-popup.component.html',
  styleUrls: ['./payment-popup.component.css']
})
export class PaymentPopupComponent {
  instId: string;
  cartId: string;
  amount: string;
  currency: string;
  MC_userID: string;
  authValidFrom:string;
  authValidTo:string;
  redirectUrl: string = window.location.origin + "/#/payment-response";
  constructor(
    public dialogRef: MatDialogRef<PaymentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    this.instId = data.instId;
    this.cartId = data.cartId;
    this.amount = data.amount;
    this.currency = data.currency;
    this.MC_userID = data.MC_userID;
    this.authValidFrom=data.authValidFrom;
    this.authValidTo=data.authValidTo;
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  submitForm(): void {
    const form = document.querySelector('form');
    if (form) {
      console.log(this.instId+":"+this.amount+":"+this.currency+":"+this.cartId+":"+this.MC_userID);
      form.submit();
    }
    //open window logic
    // const form = document.querySelector('form') as HTMLFormElement;
    // if (form) {
    //   const paymentWindow = window.open('', 'paymentWindow', 'width=800,height=600');
    //   form.target = 'paymentWindow';
    //   form.submit();

    //   // Listen for messages from the payment window
    //   window.addEventListener('message', (event) => {
    //     if (event.origin === window.location.origin && event.data.paymentCompleted) {
    //       const url = new URL(event.data.url);
    //       const params = new URLSearchParams(url.search);
    //       this.dialogRef.close();
    //       this.router.navigate(['/payment-response'], { queryParams: Object.fromEntries(params.entries()) });
    //     }
    //   });

    //   const checkWindowClosed = setInterval(() => {
    //     if (paymentWindow.closed) {
    //       clearInterval(checkWindowClosed);
    //     }
    //   }, 500);
    // }
  }
}
