import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Model, Serializer, StylesManager } from "survey-core";
import { jquerybarrating, icheck, select2, inputmask, jqueryuidatepicker, nouislider, select2tagbox, sortablejs, ckeditor, autocomplete, bootstrapslider,prettycheckbox } from "surveyjs-widgets";
import { init as initCustomWidget } from "../customwidget";
import * as SurveyCore from "survey-core";


icheck(SurveyCore);
select2(SurveyCore);
inputmask(SurveyCore);
jquerybarrating(SurveyCore);
jqueryuidatepicker(SurveyCore);
nouislider(SurveyCore);
select2tagbox(SurveyCore);
//signaturepad(SurveyCore);
sortablejs(SurveyCore);
ckeditor(SurveyCore);
autocomplete(SurveyCore);
bootstrapslider(SurveyCore);
//prettycheckbox(SurveyCore);
//emotionsratings(SurveyCore);
initCustomWidget(SurveyCore);

Serializer.addProperty("questionbase", "popupdescription:text");
Serializer.addProperty("page", "popupdescription:text");

//StylesManager.applyTheme("defaultV2");



 

@Component({
  // tslint:disable-next-line:component-selector
  selector: "survey-component",
  template: `<div class="survey-container contentcontainer codecontainer">
  <survey [model]="surveyModel"></survey>
  </div>`,
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit,AfterViewInit {
  @Output() submitSurvey = new EventEmitter<any>();
  @Input() data : Object;
  @Input()
  json: object;
  @Input()
  config:object;
  result: any;
  @ViewChild('model') surveyModel: Model;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
   
    
    StylesManager.applyTheme("defaultV2");
    this.surveyModel = new Model(this.json);

    if(this.config){
      if(this.config["mode"]){
        this.surveyModel.mode=this.config["mode"];
      }
    }
    this.surveyModel.data=this.data;
    this.surveyModel.onAfterRenderQuestion.add((survey, options) => {
      if (!options.question.popupdescription) {
        return;
      }
      // Add a button;
      const btn = document.createElement("button");
      btn.className = "btn btn-info btn-xs";
      btn.innerHTML = "More Info";
      btn.onclick = function () {
        // showDescription(question);
        alert(options.question.popupdescription);
      };
      const header = options.htmlElement.querySelector("h5");
      const span = document.createElement("span");
      span.innerHTML = "  ";
      header.appendChild(span);
      header.appendChild(btn);
    });
    this.surveyModel.onComplete.add((result, options) => {
      this.submitSurvey.emit(result.data);
      this.result = result.data;
    });
    this.cdr.detectChanges();
  }

 

  ngOnInit() {
   
   }
}
