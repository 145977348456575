import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";





@Injectable()
export class ReportDataService {
  constructor(
    private http: HttpClient
  ) {}

  getGenomeFile(fileName,type): Observable<any> {
    if(type=="text"){
      return this.http.get(fileName, {responseType:'text'});
    }else{
      return this.http.get<Array<any>>(fileName);
    }
    
  }

 
}
