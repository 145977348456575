<mat-dialog-content>
    <form #paymentForm action="https://secure-test.worldpay.com/wcc/purchase" method="POST" >
      <input type="hidden" name="instId" [value]="instId">
      <input type="hidden" name="testMode" value="100"> <!-- 100 instructs our test system -->
      <input type="hidden" name="cartId" [value]="cartId">
      <input type="hidden" name="amount" [value]="amount">
      <input type="hidden" name="currency" [value]="currency">
      <input type="hidden" name="MC_cartId" [value]="cartId">
      <input type="hidden" name="MC_userID" [value]="MC_userID">
      <input type="hidden" name="signature" value="93761a02714524085c3be0f2001496d1">
      <input type="hidden" name="authValidFrom" [value]="authValidFrom">
      <input type="hidden" name="authValidTo" [value]="authValidTo">

      <!-- <input type="hidden" name="successURL" [value]="redirectUrl"> -->
  <!-- The below parameters are all optional in this submission, but some become mandatory on the payment page -->
  <!-- The below optional parameters control the behaviour of the payment pages -->
  <!-- This optional parameter is for testing, only relevant if you're creating your own messages files -->
  <!-- The below optional parameters can be used in the payment pages -->
    <input type="hidden" name="amountString" [value]="amount">
    <input type="hidden" name="countryString" value="Full name of the country produced from the country code">
    <input type="hidden" name="compName" value="Name of the company">
  <!-- The below optional parameters instruct us to return data in the transaction result -->
    <input type="hidden" name="transId" value="Worldpay's ID for the transaction">
    <input type="hidden" name="futurePayId" value="Worldpay's ID for a FuturePay (recurring) agreement, if applicable">
    <input type="hidden" name="transStatus" value="Result of this transaction">
    <input type="hidden" name="transTime" value="Time of this transaction">
    <input type="hidden" name="authAmount" value="Amount the transaction was authorised for">
    <input type="hidden" name="authCurrency" [value]="currency">
    <input type="hidden" name="authAmountString" [value]="amount">
    <input type="hidden" name="rawAuthMessage" value="Text received from bank">
    <input type="hidden" name="rawAuthCode" value="Single character authorisation code">
    <input type="hidden" name="callbackPW" value="Payment Responses password, if set">
    <input type="hidden" name="cardType" value="Type of card used by the shopper">
    <input type="hidden" name="countryMatch" value="Result of comparison between shopper's country and card issuer country">
    <input type="hidden" name="AVS" value="Address Verification Service result">

    <!--Fix Contact hidecontact-->
    <input type="hidden" name="fixContact">
    <input type="hidden" name="hideContact">
    <input type="hidden" name="address1" value="London address">
    <input type="hidden" name="town" value="London">
    <input type="hidden" name="country" value="GB">
    <input type="hidden" name="email" value="vinish.george@meganexus.com">

    <!---->
  <!-- The below optional parameters are prefixes for types of custom parameter -->
    <!-- <input type="hidden" name="C_" value="Prefix for parameters only used in the result page">
    <input type="hidden" name="M_" value="Prefix for parameters only used in the payment responses">
    <input type="hidden" name="MC_" value="Prefix for parameters used in the both the result page and payment responses">
    <input type="hidden" name="CM_" value="Prefix for parameters used in the both the result page and payment responses"> -->
    <!-- <input type=submit value=" Buy This "> Creates the button. When clicked, the form submits the purchase details to us. -->
  </form>
  
  
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="closePopup()">Close</button>
    <button mat-raised-button color="primary" (click)="submitForm()">Pay Now</button>
  </mat-dialog-actions>
  