import { Component, OnInit } from "@angular/core";
import wellbeingJSON  from "../../../assets/wellbeing-survey.json";
import must from "../../../assets/surveys/template/MUST-2.json";
import json from "../../../assets/surveys/template/pre-admission.json";
import oral from "../../../assets/surveys/template/oral_health.json";
import { SurveyService } from "./survey.service";
import "survey-core/defaultV2.min.css";
import { ActivatedRoute } from "@angular/router";
import { SurveyCreatorModel } from "survey-creator-core";
import { SchemaDefinition } from "./schemaDefinition.model";



@Component({
  selector: "creator-page",
  templateUrl: "./creator.page.html",
  styleUrls: ['./creator.css']
  
})
export class CreatorPage implements OnInit {
  json:any;
  type:string="Select";
  id:string;
  data:SchemaDefinition;
  constructor(private readonly route: ActivatedRoute, private readonly surveyService: SurveyService) {
   
    
  }
  ngOnInit(): void {
    this.data= this.route.snapshot.data["data"];
    if(this.data){
      this.json=this.data.definition;
      this.type=this.data.type;
    }
    //this.getData(this.id);
    
  }
  getData(id: string) {
   
  }

  onSurveySaved(survey) {
    if(this.type!=="Select"){
     
      this.json = survey;
      this.surveyService.setUpData(this.json,this.type,this.data).subscribe(data=>{
        console.log("creator data saved ", data);
        alert("Saved");
      });
    }else{
      alert("Plese select the type");
    }
  }
}
