import { Component, OnInit, ViewChild } from "@angular/core";

import weelbeing from "../../../assets/wellbeing-survey.json";
import must from "../../../assets/surveys/template/MUST-2.json";
import json from "../../../assets/surveys/template/pre-admission.json";
import oral from "../../../assets/surveys/template/oral_health.json";
import { SurveyComponent } from "./survey.component";
import { SurveyService } from "../creator/survey.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "survey-page",
  templateUrl: "./survey.page.html",
  styleUrls: ['./survey.page.css'],
  styles: ['.grid-container {background-color:rgb(255, 255, 255)}']
})
export class SurveyPage implements OnInit {
  @ViewChild('survey') survey: SurveyComponent;
  title = "SurveyJS+Angular Demo Application";
  json;
  data;
  id;
  constructor(private readonly surveyService: SurveyService, private route: ActivatedRoute,private readonly router: Router) {
  }
  ngOnInit(): void {
    this.id= this.route.snapshot.params.id;
    this.json = this.route.snapshot.data.data.definition;
    console.log("got data",this.json);
  }


  sendData(result) {
    //TODO update with your own behavior
    console.log(result);

    this.surveyService.saveResponse(result, this.id).subscribe(data=>{
      console.log("Data saved => ",data);
      alert("Saved response");
      this.router.navigate(['survey-reponses',this.id]);
    });

  }
}
