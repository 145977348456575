import { Pipe, PipeTransform } from '@angular/core';
import objectScan from 'object-scan';

@Pipe({
  name: 'getNestedData'
})
export class GetNestedDataPipe implements PipeTransform {

  transform(value: Object, path: string): unknown {

    const find = objectScan([path], {
      useArraySelector: false,
      rtn: 'value'
    });
    return find(value);
  }

}
