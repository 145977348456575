import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApplyReportComponent } from './apply-report/apply-report.component';
import { ReportDataService } from './apply-report/report-data.service';
import { AzDashboardComponent } from './az-dashboard/az-dashboard.component';
import { AzDragDropComponent } from './az-drag-drop/az-drag-drop.component';
import { AzNavComponent } from './az-nav/az-nav.component';
import { AzTableComponent } from './az-table/az-table.component';
import { AzTreeComponent } from './az-tree/az-tree.component';
import { MatSpinnerOverlayComponent } from './mat-spinner-overlay/mat-spinner-overlay.component';
import { MenuComponent } from './menu/menu.component';
import { CreatorPage } from './survey-js/creator/creator.page';
import { SurveyCreatorComponent } from './survey-js/creator/survey.creator.component';
import { SurveyComponent } from './survey-js/survey/survey.component';
import { SurveyPage } from './survey-js/survey/survey.page';
import { StyleManagerService } from './theme/style-manager.service';
import { ThemeService } from './theme/theme.service';
import { ChatBotComponent } from './chat-bot/chat-bot.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { H5pReactPlayerComponent } from './h5p-react-player/h5p-react-player.component';
import { H5pContentListComponent } from './h5p-content-list/h5p-content-list.component';
import { H5pPlayerComponent } from './h5p-player/h5p-player.component';
import { SurveyCreatorModule } from 'survey-creator-angular';
import { SurveyModule } from 'survey-angular-ui';
import { SurveyView } from './survey-js/survey/survey-view.page';
import { SchemaListComponent } from './survey-js/creator/list/schema-list/schema-list.component';
import { SplitPipePipe } from './utilities/pipes/split-pipe.pipe';
import { GetNestedDataPipe } from './utilities/pipes/get-nested-data.pipe';
import { SchemaResponsesComponent } from './survey-js/schema-responses/schema-responses.component';
import { SchemaDashboardComponent } from './survey-js/schema-dashboard/schema-dashboard.component';
import { AuthModule } from '@auth0/auth0-angular';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './calendar/calendar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaymentPopupComponent } from './payment-popup/payment-popup.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { CourseBookingComponent } from './course-booking/course-booking.component';

const msalInstance: IPublicClientApplication = new PublicClientApplication({
  auth: {
    clientId: environment.clientId,
    authority: environment.issuerURL
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
  }
})
@NgModule({
  declarations: [
    AppComponent,
    ApplyReportComponent,
    AzNavComponent,
    AzDashboardComponent,
    AzTableComponent,
    AzTreeComponent,
    AzDragDropComponent,
    MatSpinnerOverlayComponent,
    MenuComponent,
    SurveyComponent,
    SurveyPage,
    SurveyCreatorComponent,
    CreatorPage,
    SurveyView,
    FileUploaderComponent,
    H5pReactPlayerComponent,
    H5pContentListComponent,
    H5pPlayerComponent,
    SchemaListComponent,
    SplitPipePipe,
    GetNestedDataPipe,
    SchemaResponsesComponent,
    SchemaDashboardComponent,
    CalendarComponent,
    PaymentPopupComponent,
    PaymentResponseComponent,
    CourseBookingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatSliderModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTreeModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDialogModule,
    MatExpansionModule,
    DragDropModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SurveyCreatorModule,
    SurveyModule,
    MsalModule.forRoot(msalInstance,
      {
        interactionType: InteractionType.Popup
      },
      {
        interactionType: InteractionType.Popup,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']]
        ])
      }),
    AuthModule.forRoot({
      domain: 'vinish-george-dev.us.auth0.com',
      clientId: '4jHTXBp3ubaOYAgZh2RcI6V9GIqCk0Nr',
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    }), 
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }), NgbModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ThemeService, StyleManagerService, ReportDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
