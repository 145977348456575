import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { defineElements } from '@lumieducation/h5p-webcomponents';
import { ContentService } from '../h5p-react-player/services/ContentService';

@Component({
  selector: 'app-h5p-player',
  templateUrl: './h5p-player.component.html',
  styleUrls: ['./h5p-player.component.css']
})
export class H5pPlayerComponent implements OnInit, OnDestroy {
  id: string;
  h5pPlayer: any;

  constructor(private readonly contentService: ContentService,
    private route: ActivatedRoute,
    private elementRef: ElementRef, private router: Router) { }

  ngOnDestroy(): void {
    this.h5pPlayer.removeEventListener('xAPI', this.contentService.sendXApi);
  }

  ngOnInit(): void {
    defineElements('h5p-player'); // only registers the player component
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadPlayer(this.id);
  }

  loadPlayer(contentId) {
    this.h5pPlayer = document.createElement('h5p-player');
    this.h5pPlayer.setAttribute('content-id', contentId);
    this.h5pPlayer.loadContentCallback = this.contentService.getPlay;
    this.h5pPlayer.addEventListener('xAPI', this.contentService.sendXApi);

    let someOtherElement = document.getElementById("h5p-player-container");
    someOtherElement.appendChild(this.h5pPlayer);

  }

  back() {
    this.router.navigate(['/h5p-list'])
  }

}
