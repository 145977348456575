import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SchemaDefinition } from '../../schemaDefinition.model';
import { SurveyService } from '../../survey.service';

@Component({
  selector: 'app-schema-list',
  templateUrl: './schema-list.component.html',
  styleUrls: ['./schema-list.component.css']
})
export class SchemaListComponent implements  OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<SchemaDefinition>;
  dataSource = new MatTableDataSource<SchemaDefinition>();
  constructor(private readonly surveyService: SurveyService,private readonly router: Router) {

  }
  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['name','definition.description','updateDate','actions'];

  ngOnInit() {
    this.getData();

  }

  getData() {
    this.surveyService.getSchemaDefinitions().subscribe(schamaDefinitions => {
      this.dataSource.data = schamaDefinitions;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  view(data){
    this.router.navigate(['survey-view',data["id"],'63e1fb674424c915d78dbbe7']);
  }
  edit(data){
    this.router.navigate(['survey-edit',data["id"]]);
  }
  delete(data){
    let confirmation=confirm("Are you sure?");
    if(confirmation){
      this.surveyService.deleteSchemaDefinition(data["id"]).subscribe(data=>{
        console.log("Deleted .. ",data);
        this.getData()
      });
    }
  }
  answer(data){
    this.router.navigate(['survey',data["id"]]);
  }
  create(){
    this.router.navigate(['creator']);
  }
  responses(data){
    this.router.navigate(['survey-reponses',data["id"]]);
  }
 
  report(data) {
    this.router.navigate(['survey-report', data["id"]]);
  }
}
