import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VisualizationPanel } from 'survey-analytics';
import { Model } from 'survey-core';
import { data, json } from "./gad7";
import { Router, ActivatedRoute } from '@angular/router';
import { SurveyService } from '../creator/survey.service';
import { SchemaDefinition } from '../creator/schemaDefinition.model';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

const surveyJson = {
  elements: [{
    name: "satisfaction-score",
    title: "How would you describe your experience with our product?",
    type: "radiogroup",
    choices: [
      { value: 5, text: "Fully satisfying" },
      { value: 4, text: "Generally satisfying" },
      { value: 3, text: "Neutral" },
      { value: 2, text: "Rather unsatisfying" },
      { value: 1, text: "Not satisfying at all" }
    ],
    isRequired: true
  }, {
    name: "nps-score",
    title: "On a scale of zero to ten, how likely are you to recommend our product to a friend or colleague?",
    type: "rating",
    rateMin: 0,
    rateMax: 10,
  }],
  showQuestionNumbers: "off",
  completedHtml: "Thank you for your feedback!",
};

const surveyResults = [{
  "satisfaction-score": 5,
  "nps-score": 10
}, {
  "satisfaction-score": 5,
  "nps-score": 9
}, {
  "satisfaction-score": 3,
  "nps-score": 6
}, {
  "satisfaction-score": 3,
  "nps-score": 6
}, {
  "satisfaction-score": 2,
  "nps-score": 3
}];
const vizPanelOptions = { allowHideQuestions: false };

@Component({
  selector: 'app-schema-dashboard',
  templateUrl: './schema-dashboard.component.html',
  styleUrls: ['./schema-dashboard.component.css']
})
export class SchemaDashboardComponent implements OnInit, AfterViewInit {

  constructor(private readonly surveyService: SurveyService, private readonly router: Router, private readonly route: ActivatedRoute) { }
  title = 'SurveyJS Analytics for Angular';
  @ViewChild("surveyVizPanel") elem: ElementRef | undefined;

  ngAfterViewInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    const req1 = this.surveyService.getSchemaDefinition(id);
    const req2 = this.surveyService.getSchemaResponses(id);
    forkJoin([req1,req2]).subscribe(results => {
      let schemaDefinition = JSON.parse(JSON.stringify(results[0]));
      let data: any =this.filterVisualizedQuestion(results[0]["definition"]["pages"][0]) ;
      const responses: any = results[1];
      const responseArray: any = this.collectResponses(responses);
      if(!data || data.elements.length === 0) {
        data = schemaDefinition["definition"] ;
      }
      const survey = new Model(data);
      const vizPanel = new VisualizationPanel(
        survey.getAllQuestions(),
        responseArray,
        vizPanelOptions
      );
      vizPanel.render(this.elem?.nativeElement);

    });




  }

  private collectResponses(responses: any) {
    const responseArray: any = [];
    for (let i = 0; i < responses.length; i++) {
      responseArray.push(responses[i].responseJSON);

    }
    return responseArray;
  }

  ngOnInit(): void {

  }

  filterVisualizedQuestion(survey: any): any {
    const questions = survey.elements;
    console.log(questions);
    const isVisualizedFilter = (question: any) => {
      console.log(question["isVisualized"]);
      return question["isVisualized"] ? true : false;
    };
    const visualizedQuestions = questions.filter(isVisualizedFilter);

    survey.elements = visualizedQuestions;
    return survey;
  }

}
