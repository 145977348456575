<div class="grid-container">
    <h1 class="mat-h1">File Actions</h1>
    <mat-grid-list cols="2" rowHeight="350px">
        <mat-grid-tile [colspan]="2" [rowspan]="2">
            <mat-card class="dashboard-card">
                <mat-card-header>
                    <mat-card-title>
                        Vinish
                        <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before">
                            <button mat-menu-item>Expand</button>
                            <button mat-menu-item>Remove</button>
                        </mat-menu>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content class="dashboard-card-content">
                    <form [formGroup]="fileUploadForm">
                        <div>
                            <p class="15space">
                                <mat-form-field appearance="standard">
                                    <mat-label>File Name</mat-label>
                                    <input matInput placeholder="File Name" formControlName="fileName">
                                </mat-form-field>
                            </p>
                            <p class="15space">
                                <button type="button" mat-raised-button (click)="fileInput.click()">Choose File</button>
                                <input hidden (change)="onFileSelected($event)" #fileInput type="file"
                                    formControlName="fileSource">
                                <span class="file-name">{{selectedFile?.name}}</span>
                            </p>
                            <button mat-raised-button aria-label="Upload File" (click)="uploadFile()"
                                [disabled]='!fileUploadForm.valid'>Upload File</button>

                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<app-mat-spinner-overlay [overlay]="this.overlay" [hidden]="!this.overlay"></app-mat-spinner-overlay>